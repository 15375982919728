<template>
  <v-container>
    <div class="snt-admin">
      <h1 class="snt-admin-headline text-center">
        Administrator
      </h1>
      <div class="d-flex flex-column justify-center">
        <v-btn
          to="/admin/templates"
          class="snt-admin__link"
          color="primary"
          rounded
          outlined
          large
          block>
          Templates
        </v-btn>
        <v-btn
          to="/"
          class="snt-admin__link"
          color="#666"
          rounded
          outlined
          large
          block>
          Exit
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
  .snt-admin {
    max-width: 320px;
    margin: 0 auto;
  }
  .snt-admin-headline {
    font-size: 3em;
    margin: 2.5em 0 0.25em;
    max-width: 320px;
  }
  .snt-admin__link {
    color: #6C6C6C;
    margin: 6px 0;
  }
</style>
